<template>
    <layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Create an Admin</h4>
                    <form ref="adminForm" class="needs-validation" @submit.prevent="createAdmin">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="validationCustom01">Name</label>
                                    <input
                                            id="validationCustom01"
                                            v-model="name"
                                            type="text"
                                            class="form-control"
                                            placeholder="Name"
                                            :class="{ 'is-invalid': submitted && $v.form.fname.$error }"
                                    />
                                    <div v-if="submitted && $v.name.$error" class="invalid-feedback">
                                        <span v-if="!$v.name.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label >Surname</label>
                                    <input
                                            v-model="surname"
                                            type="text"
                                            class="form-control"
                                            placeholder="Surname"
                                    />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label >Username</label>
                                    <input
                                            type="text"
                                            v-model="username"
                                            class="form-control"
                                            placeholder="Username"
                                            :class="{ 'is-invalid': submitted && $v.form.state.$error }"
                                    />
                                    <div v-if="submitted && $v.form.state.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.state.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label >Country</label>
                                    <multiselect  v-model="country" label="country_name"  :options="options" @select="selected" :multiple="false" placeholder=" Select country"></multiselect>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label >Email</label>
                                    <input
                                            type="email"
                                            v-model="email"
                                            class="form-control"
                                            placeholder="Email"
                                            :class="{ 'is-invalid': submitted && $v.email.$error }"
                                    />
                                    <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                        <span v-if="!$v.email.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label >Password</label>
                                    <input
                                            v-model="password"
                                            type="password"
                                            class="form-control"
                                            placeholder="Password"
                                    />
                                    <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                                        <span v-if="!$v.form.state.required">This value is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="submit">Create</button>
                    </form>
                </div>
                <div class="lodader__content" v-if="loader">
                    <b-spinner style="width: 3rem; height: 3rem" class="loader" variant="success" label="Spinning"></b-spinner>
                </div>
            </div>
            <!-- end card -->
        </div>
    </div>
    </layout>

    
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Multiselect from "vue-multiselect";

    import {
        required,
        email,
        minLength,
        sameAs,
        maxLength,
        minValue,
        maxValue,
        numeric,
        url,
        alphaNum
    } from "vuelidate/lib/validators";
    import {axios_post} from "../../../helpers/helper";

    /**
     * Form validation component
     */
    export default {
        page: {
            title: "Create Admin",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader, Multiselect },
        data() {
            return {
                options:[],
                value:null,
                loader: false,
                countries:[],
                title: "Create Admin",
                items: [
                    {
                        text: "Admin",
                        href: "/"
                    },
                    {
                        text: "Create Admin",
                        active: true
                    }
                ],
                password: "",
                username:"",
                country:"",
                name:"",
                email:"",
                form: {
                    fname: "",
                    lname: "",

                    city: "",
                    state: "",
                    zipcode: ""
                },
                tooltipform: {
                    fname: "",
                    lname: "",
                    email: "",
                    zipcode: "",
                    city: "",
                    state: ""
                },
                range: {
                    minlen: "",
                    maxlength: "",
                    between: "",
                    minval: "",
                    maxval: "",
                    rangeval: "",
                    expr: ""
                },
                typeform: {
                    name: "",
                    password: "",
                    confirmPassword: "",
                    email: "",
                    url: "",
                    digit: "",
                    number: "",
                    alphanum: "",
                    textarea: ""
                },
                submitted: false,
                submitform: false,
                submit: false,
                typesubmit: false
            };
        },
        validations: {
            form: {
                fname: { required },
                lname: { required },
                city: { required },
                email: { required },
                state: { required },
                zipcode: { required }
            },
            tooltipform: {
                fname: { required },
                lname: { required },
                email: { required },
                username: { required },
                city: { required },
                state: { required }
            },
            range: {
                minlen: { required, minLength: minLength(6) },
                maxlength: { required, maxLength: maxLength(6) },
                between: { required, minLength: minLength(5), maxLength: maxLength(10) },
                minval: { required, minValue: minValue(6) },
                maxval: { required, maxValue: maxValue(6) },
                rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
                expr: { required }
            },
            typeform: {
                name: { required },
                password: { required, minLength: minLength(6) },
                confirmPassword: { required, sameAsPassword: sameAs("password") },
                email: { required, email },
                url: { required, url },
                digit: { required, numeric },
                number: { required, numeric },
                alphanum: { required, alphaNum },
                textarea: { required }
            }
        },
        methods: {
            createAdmin(){
                this.submitted = true;
                this.loader = true;
                // stop here if form is invalid
                this.$v.$touch();
                axios_post("/register",{
                    name:this.name,
                    username:this.username,
                    password:this.password,
                    email:this.email,
                    country:this.country.country_name,
                    country_id:this.country.country_id,
                    role:"ADMIN",
                },()=>{
                    window.location.href='/admins';
                },(e)=>{
                    console.log("error",e);
                },()=>{
                    console.log("finishing");
                    this.loader = false;
                })

            },
            resetForm(){
                this.$refs['adminForm'].reset()
            },
            selected(sValue){
                console.log(sValue)
                this.country=sValue
                console.log(this.country.country_id)
            },
            // eslint-disable-next-line no-unused-vars
            // formSubmit(e) {
            //     this.submitted = true;
            //     // stop here if form is invalid
            //     this.$v.$touch();
            // },

            tooltipForm() {
                this.submitform = true;
                this.$v.$touch();
            },

            /**
             * Range validation form submit
             */
            // eslint-disable-next-line no-unused-vars
            rangeform(e) {
                this.submit = true;
                // stop here if form is invalid
                this.$v.$touch();
            },
            /**
             * Validation type submit
             */
            // eslint-disable-next-line no-unused-vars
            typeForm(e) {
                this.typesubmit = true;
                // stop here if form is invalid
                this.$v.$touch();
            }
        },
        mounted(){
            console.log(localStorage.getItem('country'))
            /* this.countries=JSON.parse(localStorage.getItem('country')),
                // this.options=this.countries.map(e=>(e.country_name))
                this.options=this.countries
            console.log(this.options) */
            this.options=JSON.parse(localStorage.getItem('country')),
            this.options=this.options.sort((a, b)=>{
                if(a.country_name <= b.country_name) return -1;
                else return 1;
            })
        }
    };

</script>

<style scoped>
    .lodader__content{
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .1);
        width: 100%;
        height: 100%;
    }

    .loader{
        position: relative;
        left: 50%;
        top: 50%;
    }
</style>